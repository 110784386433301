import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import Trivia from "../../../components/trivia"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopJuniPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/juni/materialien.jpg" }
      ) {
        ...largeImage
      }
      vorlage: file(relativePath: { eq: "vorlage-juni.pdf" }) {
        publicURL
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/juni/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/juni/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-juni">
      <Seo
        title="Juni"
        description="Um den Sommer willkommen zu heißen, schreiben wir für ihn ein Gedicht in Form eines Akrostichons. Ein Akrostichon ist ein Gedicht, bei dem die Anfangsbuchstaben der Verse ein eigenes Wort ergeben. Passend zur Jahreszeit haben wir uns das Wort S-O-M-M-E-R ausgesucht."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Juni",
              link: "/workshops/kalender/juni",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>Juni</PageTitle>
            <Paragraph dropcap={true}>
              Um den Sommer willkommen zu heißen, schreiben wir für ihn ein
              Gedicht in Form eines Akrostichons. Ein Akrostichon ist ein
              Gedicht, bei dem die Anfangsbuchstaben der Verse ein eigenes Wort
              ergeben. Passend zur Jahreszeit haben wir uns das Wort S-O-M-M-E-R
              ausgesucht.
            </Paragraph>
            <Trivia>
              Das Akrostichon gibt es schon ganz lange. Es wurde vor allem im
              Mittelalter und in der Zeit des Barocks vor über 300 Jahren sehr
              gerne verwendet. Erfunden wurde es sogar schon viel früher, im
              antiken Griechenland. Darauf verweist auch der Name. Akrostichon
              kommt aus dem Altgiriechischen und steht für ákros' (Spitze) und
              stíchos (Vers oder Zeile). Die deutsche Bezeichung für diese
              Gedichtform ist ›Leistenvers‹ oder auch ›Leistengedicht‹. Die
              Buchstaben sind wie in einer Leiste angeordnet.
            </Trivia>
          </Stack>

          <Constrain>
            <Stack>
              <Stack>
                <Heading as="h2" level={2}>
                  Du brauchst...
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Die Buchstaben S-O-M-M-E-R aus deinem Bastelset. Du
                      findest sie{" "}
                      <TextLink href={data.vorlage.publicURL}>
                        auch hier zum Ausdrucken
                      </TextLink>
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Eine Schere und einen Klebestift
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Buntstifte
                    </Text>
                  </Checkbox>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Die Anleitung zum Kalenderblatt mit den Buchstaben S,O,M,M,E,R zum Ausschneiden"
                />
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Schneide die Buchstaben aus der Vorlage aus und klebe sie in
                    der richtigen Reihenfolge untereinander auf deine
                    Kalenderseite.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={2}>
                  <Paragraph>
                    Im nächsten Schritt schreibst du zu jedem Buchstaben einen
                    Gedichtvers oder einen Satz. Denke daran, dass das 1. Wort
                    immer mit dem passenden Anfangsbuchstaben beginnen muss.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Paragraph>
                    Die 6 Verse sollten ein zusammenhängendes Gedicht ergeben
                    und zum Thema Sommer passen.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={4}>
                  <Paragraph>
                    Zuletzt kannst du deine Anfangsbuchstaben ausmalen und deine
                    Kalenderseite sommerlich gestalten.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3}>
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Detailansicht der fertigen Kalenderseite. Bunte Buchstaben und ein handgeschriebenes Gedicht"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Der Buchstabe E ist fokussiert"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
